import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDebouncedCallback } from "use-debounce";

import { vinCheckAPI } from "../api";

import Header from "../components/header/header";
import Loader from "../components/loader/loader";
import { validateVin, pluralizeText, isEmail } from "../utils";

import PDF from "../static/example.pdf";
import car from "../assets/images/main_car.png";

import "../index.scss";
import { instance } from "../api/settings";

const IndexPage = () => {
  const handleCheckPromocodeDebounced = useDebouncedCallback(() => {
    handleCheckPromocode();
  }, 500);

  const payBtn = useRef(null);

  const [vin, setVin] = useState("");
  const [isValidVin, setIsValidVin] = useState(true);

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const [promoCode, setPromoCode] = useState("");
  const [promocodeRight, setPromocodeRight] = useState("");
  const [promocodeError, setPromocodeError] = useState("");

  const [priceDiscount, setPriceDiscount] = useState(null);

  const [isCheckingPromocode, setIsCheckingPromocode] = useState(false);

  const [isVinChecking, setIsVinChecking] = useState(false);
  const [isEmailChecking, setIsEmailChecking] = useState(false);
  const [isVinReady, setIsVinReady] = useState(false);
  const [vinData, setVinData] = useState({});
  const [order, setOrder] = useState({});
  const [isConditionCheck, setIsConditionCheck] = useState(false);
  const [isEmailDisclaimerVisible, setIsEmailDisclaimerVisible] = useState(
    false
  );

  const infoItemList = [
    "нахождение в залоге на территории Республики Беларусь",
    "нахождение в залоге на территории РФ",
    "нахождение в розыске на территории РФ",
    "историю эксплуатации в РФ (регистрационные действия, ДТП, пробеги)",
    "историю размещений на площадках объявлений в Республике Беларусь",
  ];

  const auth = (userId) => {
    localStorage.setItem("user-id", userId);
    instance.defaults.headers["user-id"] = userId;
  };

  useEffect(() => {
    if (vin.length < 17) {
      setIsValidVin(true);
      return;
    }

    setIsValidVin(validateVin(vin));
  }, [vin]);

  useEffect(() => {
    if (isConditionCheck) {
      setIsEmailDisclaimerVisible(false);
    }
  }, [isConditionCheck]);

  const interval = useRef(null);

  const checkVinInterval = () => {
    interval.current = setInterval(() => checkVin(), 2000);
  };

  const checkVin = async () => {
    try {
      const data = await vinCheckAPI.checkVin(vin);
      const { ready } = data.vin;

      if (ready) {
        clearInterval(interval.current);
        setIsVinChecking(false);
        setIsVinReady(true);
        setVinData(data.vin);
      }
    } catch (e) {
      clearInterval(interval.current);
      setIsVinChecking(false);
      console.log(e);
    }
  };

  const handleSubmitVIN = async (e) => {
    e.preventDefault();

    if (!isValidVin) return;

    try {
      const response = await vinCheckAPI.submitVin({ vin });
      const userId = response.headers["user-id"];

      auth(userId);
      setIsVinChecking(true);
      checkVinInterval();
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmitEmail = async (e) => {
    e.preventDefault();

    if (!isConditionCheck) {
      setIsEmailDisclaimerVisible(true);
      return;
    }

    setIsEmailChecking(true);

    try {
      const { order } = await vinCheckAPI.setOrder({
        vin,
        email,
        code: promocodeRight,
      });

      setOrder(order);

      payBtn.current.click();

      setIsEmailChecking(false);
    } catch (e) {
      console.log(e);
      setIsEmailChecking(false);
    }
  };

  const handleConditionChange = (e) => {
    setIsConditionCheck(e.target.checked);
  };

  const promocodeInvalidHandler = (isSelfPromocode = false) => {
    setPromocodeRight("");
    setPriceDiscount(null);
    setPromocodeError(
      isSelfPromocode
        ? "Нельзя использовать свой промокод"
        : "Промокода не существует"
    );
  };

  const handleCheckPromocode = async () => {
    setPromocodeError("");
    setEmailError("");

    const isEmailValid = isEmail(email);

    if (!isEmailValid) return setEmailError("Введите корректный email");

    try {
      setIsCheckingPromocode(true);
      let isValidPromocode = false;
      let newPrice = null;

      if (promoCode?.length >= 6) {
        const codeResponse = await vinCheckAPI.checkPromocodeAndEmail({
          promoCode,
          email,
        });

        if (codeResponse.exists && !codeResponse.self) {
          isValidPromocode = true;
          newPrice = codeResponse.price;
        } else {
          promocodeInvalidHandler(codeResponse.self);
        }
      }

      if (!isValidPromocode) {
        let emailResponse = await vinCheckAPI.checkPromocodeAndEmail({ email });

        if (emailResponse.exists) newPrice = emailResponse.price;
      }

      setPromocodeRight(promoCode.length >= 6 ? promoCode : "");
      setPriceDiscount(newPrice);
    } catch (error) {
      promocodeInvalidHandler();
    } finally {
      setIsCheckingPromocode(false);
    }
  };

  const onChangePromocodeHandler = (e) => {
    const value = e.target.value;
    setPromoCode(value.toLowerCase());
    setPromocodeError("");
    (value.length >= 6 || promocodeRight) && handleCheckPromocodeDebounced();
  };

  const onChangeEmailHandler = (e) => {
    setEmailError("");
    setEmail(e.target.value);
    handleCheckPromocodeDebounced();
  };

  const checkPromocodeLength = () => {
    return (
      promoCode.length > 0 &&
      promoCode.length < 6 &&
      "Длина промокода не менее 6 символов"
    );
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Проверка авто по ВИН на Куфаре</title>
        <meta
          name="description"
          content="Проверить автомобиль по VIN коду на наличие залогов, ограничений и запретов, а также узнать историю размещений на Куфаре"
        />
        <meta
          name="keywords"
          content="реестр залогов, VIN, проверка на угон, проверка на залог, машины из России, VIN-декодер"
        />
      </Helmet>

      <Header />
      <div className="container index-page">
        <div className="center-wrapper">
          <h1 className="title">Проверка авто по VIN</h1>
          <h3 className="subtitle">
            Залог, угон, декодирование, история эксплуатации в РФ и другие
            данные <br /> из официальных источников
          </h3>
          <img src={car} alt="" className="car" />
          <form className="action-wrapper" onSubmit={handleSubmitVIN}>
            <div className="input-wrapper">
              <input
                type="text"
                className="input input-vin"
                placeholder="Введите VIN"
                required
                value={vin || ""}
                onChange={(e) => setVin(e.target.value)}
                maxLength={17}
                minLength={17}
                disabled={isVinChecking || isEmailChecking}
              />
              {!isValidVin && <p className="errorText">невалидный vin</p>}
            </div>
            <div className="btn-wrapper">
              <button
                className="btn-blue btn-action"
                type="submit"
                disabled={isVinChecking || isEmailChecking}
              >
                Проверить
              </button>
              <a
                href={PDF}
                target="_blank"
                rel="noopener noreferrer"
                className="example-pdf-link example-pdf-link--desktop"
              >
                пример отчета
              </a>
              <a
                href={PDF}
                download
                className="example-pdf-link example-pdf-link--mobile"
              >
                пример отчета
              </a>
            </div>
          </form>
          {isVinChecking && <Loader />}
          {isVinReady && (
            <>
              <h3 className="mark-country-title">
                {vinData.model || vinData.fullModel || vinData.manufacturer}{" "}
                {`(${vinData.country || vinData.region})`}
              </h3>

              <form className="info-wrapper" onSubmit={handleSubmitEmail}>
                <p className="info-title">Мы проверим:</p>
                <ul className="info-list">
                  {infoItemList.map((item, index) => (
                    <li className="info-list-item" key={`li_${index}`}>
                      {item}
                    </li>
                  ))}
                </ul>
                <div className="action-wrapper">
                  <div className="input-wrapper">
                    <input
                      type="email"
                      className="input input-email"
                      placeholder="e-mail"
                      required
                      value={email || ""}
                      onChange={onChangeEmailHandler}
                      disabled={isVinChecking || isEmailChecking}
                    />
                    {isEmailDisclaimerVisible && (
                      <p className="errorText">
                        подтвердите ознакомление с условиями
                      </p>
                    )}
                    {emailError && <p className="errorText">{emailError}</p>}
                  </div>
                  <button
                    className="btn-blue btn-action btn-buy"
                    type="submit"
                    disabled={
                      isVinChecking || isEmailChecking || isCheckingPromocode
                    }
                  >
                    {isCheckingPromocode ? (
                      <Loader className="loader-buy" color="white" />
                    ) : (
                      `Купить отчет за ${priceDiscount || vinData?.totalSum} BYN`
                    )}
                  </button>
                </div>
                <div className="action-wrapper">
                  <div className="input-wrapper">
                    <input
                      type="promo"
                      className="input input-email"
                      placeholder="Введите промокод"
                      value={promoCode || ""}
                      onChange={onChangePromocodeHandler}
                      maxLength="6"
                    />
                    {(promocodeError || checkPromocodeLength()) && (
                      <p className="errorText">
                        {checkPromocodeLength() || promocodeError}
                      </p>
                    )}
                  </div>
                </div>
                <div className="condition-block">
                  <input
                    type="checkbox"
                    id="condition"
                    value={isConditionCheck || false}
                    onChange={handleConditionChange}
                  />
                  <label htmlFor="condition">
                    подтверждаю ознакомление с{" "}
                    <a
                      href={process.env.CONFIRMATION_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="condition-link"
                    >
                      Условиями
                    </a>{" "}
                    оказания услуги &ldquo;Проверка Авто по VIN&ldquo;
                  </label>
                </div>
              </form>
            </>
          )}
          {isEmailChecking && <Loader />}
          <form action={order.payLink} method="POST">
            <input
              type="hidden"
              name="Merchant_ID"
              value={order.merchantId || ""}
            />
            <input type="hidden" name="OrderNumber" value={order.id || ""} />
            <input
              type="hidden"
              name="OrderAmount"
              value={order.totalSum || ""}
            />
            <input type="hidden" name="Email" value={order.email || ""} />
            <button type="submit" className="hidden" ref={payBtn} />
          </form>
        </div>
      </div>
    </>
  );
};

export default IndexPage;
